#inputFieldEmail{
    background-color: rgb(240, 240 , 238);
    border: 1px solid rgb(181, 181, 181);
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;
}
#inputFieldPW{
    background-color: rgb(240, 240 , 238);
    border: 1px solid rgb(181, 181, 181);
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;
}
#inputFieldFN {
    background-color: rgb(240, 240 , 238);
    border: 1px solid rgb(181, 181, 181);
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;
}
#inputFieldLN {
    background-color: rgb(240, 240 , 238);
    border: 1px solid rgb(181, 181, 181);
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;
}
#inputFieldBD {
    background-color: rgb(240, 240 , 238);
    border: 1px solid rgb(181, 181, 181);
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;
}
#confirmPW{
    background-color: rgb(240, 240 , 238);
    border: 1px solid rgb(181, 181, 181);
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;
}
#inputField{
    background-color: rgb(240, 240 , 238);
    border: 1px solid rgb(181, 181, 181);
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;
}
#inputLabel{
    font-family: 'Roboto Slab', serif;
}
#uploadButton{
    font-family: 'Roboto', sans-serif;
    border: 1px solid rgb(181, 181, 181);
    color: darkgray;
    font-size: 14px;
    height: 23%;
}
#uploadButton:hover{
    color: white;
}
#greenButton{
    background-color:  rgb(157, 194, 22);
    border: none;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: bold;
    width: 120%;
    height: 37px;
}
#greenButton:hover{
    background-color: gray;
    color: white;
}
#body{
    background-color: rgb(245, 245 , 240);
    font-weight: bold;
    color: orange;
}
#greenText{
    color: rgb(157, 196, 16);
}
#links{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    margin-right: 0.5%;
}
#loginButton{
    background-color:  transparent;
    color: darkgray;
    border: 1px solid  darkgray;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 16px;
    width: 135%;
    height: 105%;
    margin-left: -35%;
}
#loginButton:hover{
    color:white;
    background-color: gray;
}
#container{
    font-family: 'Roboto Slab', serif;
}
#footer{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
}
#footer #footerLinks{
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 14px;
}
#pageTitle{
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    color: rgb(157, 196, 16);
}
h2 {
    display: flex;
    align-items: baseline;
    text-align: center;
    margin-right: -15px;
}
h2:after {
    content: '';
    border-top: 1px solid;
    flex: 1;
    margin-left: 10px;
    color: lightgray;
}
input[type="text"]::-webkit-input-placeholder {
    color: darkgray;
  }
input[type="password"]::-webkit-input-placeholder {
    color: darkgray;
  }
  input[type="email"]::-webkit-input-placeholder {
    color: darkgray;
  }
  input[type="number"]::-webkit-input-placeholder {
    color: darkgray;
  }
#categoryText{ 
    background-Color: rgb(150, 190, 15);
    position:absolute;
    left:7%; 
    top:4%;
    border-Radius: 20%/50%;
    padding-top: 1%;
    color: white;
    padding-Left:3%;
    padding-Right:3%;
    padding-Bottom:1%;
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
}
#cardTitle{
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    font-size: 22px;
    border: none;
    resize: none;
    overflow: hidden;
    background-color: white;
    color:orange;
    width: 100%;
}
#cardIcons{
    font-size: 17px;
    margin-top: -1%;
    margin-right: 1%;
}
#textarea{
    overflow: hidden;
    border: none;
    resize: none;
    font-size: 15px;
    font-weight: 400;
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    background-color: white;
}
#cardBody{
    margin: 2%;
    margin-top: 1%;
}
#card{
    border-radius: 2%;
    margin-right: 3%;
}
#cardText{
    margin-top: -1.5%;
}
#cardFooter{
    border: none;
    color: gray;
    margin: -3% -5% -3% -4%;
    font-family: 'Roboto Slab', serif;
    background-color: transparent;
    width: 130%;
    position: static;
}
#cardImage{
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
    width: 100%;
    height: 250px;
    object-fit: cover;
}
#modalButton{
    background-color: rgb(150, 190, 15);
    border: none;
    color: white;
    border-radius: 20%;
    width: 8.5%;
    height: 6.5%;
    padding: 1.5%;
    margin-top: -0.5%;
    position: absolute;
    right: 6%;
}
#modalButton:hover{
    background-color: darkgray;
}
#tableHead{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin-bottom: 1%;
}
#tableRow{
    border: none;
    background-color: white;
    margin-bottom: 0.5%;
    height: 65px;
    border-radius: 1%/8%;
}
#tableCol{
    border: none;
    margin: auto;
    color: darkgray;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
}
#tableCol:first-child{
    font-weight: 600;
    margin-right: -3%;
}
#deleteButton{
    color: darkgray;
    margin-left: 45%;
    font-size: 20px;
}
#deleteButton:hover{
    color: red;
}
#categoryButton{
    background-color:  rgb(134, 169, 8);
    border: none;
    font-size: 12px;
    color: white;
    height: 26px;
    text-align: center;
    width: 46%;
    padding: 3.2%;
    font-weight: 600;
    letter-spacing: 1px;
    padding-left: 3%;
    border-radius: 5%/20%;
    margin-bottom: -1%;
}
#recipeTitle{
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: darkgray;
    resize: none;
    overflow: hidden;
    letter-spacing: 0.5px;
    margin-top: 3%;
    background-color: transparent;
}
#modalTitle{
    font-weight: 600;
    font-family: 'Roboto Slab', serif;
    color: orange;
    font-size: 38px;
    resize: none;
    border: none;
    overflow: hidden;
    background-color: transparent;
    margin-top: 2%;
}
#modalHeader{
    border: none;
    font-size: 21px;
    padding-right: 2%;
    margin-top: -2%;
    margin-bottom: -1.5%;
}
#cardModal{
    border: none;
    margin-bottom: -2%;
}
#modalImage{
    object-fit: cover;
    border-radius: 2%;
    height: 260px;
}
#modalCategory{
    color: rgb(134, 169, 8) ;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    font-size: 18px;
    width: 104%;
    margin-bottom: 3.5%;
}
#modalCategory span{
    color:white;
    background-color: rgb(134, 169, 8);
    border-Radius: 20%/50%;
    padding: 1% 2% 1% 2%;
    font-size: 14px;
    position: absolute;
    font-weight: 600;
    right: 0%;
    margin-top: -1%;
    letter-spacing: 0.5px;
}
#modalCardBody{
    margin-left: -3%;
    margin-top: 2%;
}
#modalDesc{
    font-size: 18px;
    font-family: 'Roboto Slab', serif;
    color: rgb(134, 169, 8);
    font-weight: 600;
    margin-left: 2%;
    width: 56%;
}
#modalDesc textarea{
    border: none;
    resize: none;
    font-size: 15px;
    color: darkgray;
    margin-top: 2%;
    font-family: 'Roboto', sans-serif;
    height: 89%;
    background-color: transparent;
}
#modalText textarea{
    border: none;
    overflow: hidden;
    resize: none;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    color: darkgray;
    width: 105%;
    margin-bottom: -1%;
    background-color: transparent;
    margin-left: -0.5%;
}
#modalFooter{
    border: none;
    font-size: 13px;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    color: rgb(99, 99, 99);
    margin-left: -4.5%;
    background-color: transparent;
}
#modalIcons{
    font-size: 17px;
    margin-right: 1%;
    margin-bottom: 1%;
}
#modalFooter span{
    margin: 1%
}
#alert{
    font-size: 23px;
    width: 100%;
    height: 100%;
    font-family: 'Roboto', serif;
    margin: 0;
    padding: auto;
    font-weight: 600;
    letter-spacing: 0.5px;
}
#spinner{
    height: 23px;
    width: 23px;
    position: absolute;
    right: 4%;
    top:32%;
}

